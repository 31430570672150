/* eslint-disable global-require */
import React from 'react';

function IPhoneNotSupportedSection({ anchor }) {
  return (
    <div
      css={{
        backgroundColor: 'rgb(195, 27, 108)',
        color: '#fff',
        padding: '5pt 0'
      }}
    >
      <div css={{ maxWidth: '1140px', margin: '0 auto', padding: '0 15px' }}>
        <strong>Note:</strong> If you are facing issues using this form on
        iPhone, Firefox or Safari browsers, please try on a PC or on a different
        browser, e.g. Chrome or Edge.
      </div>
    </div>
  );
}

export default IPhoneNotSupportedSection;
